export default {
    "general": {
        "login": "/login_gesto/",
        "actualizarBd": "/actualizar_bd_cliente",
        "getActualizacion": "/get_ultima_actualizacion/",
        "getEmpresas": "/get_empresas_documentos/",
        "getEmpresasInfo": "/get_empresas_info",
        "getTrabajadoresEmpresa": "/get_trabajadores_empresa/",
        "getTrabajadoresEmpresaTipoDoc": "/get_trabajadores_empresa_tipodoc/",
        "cambioCorreoTrabajador": "/cambiar_correo_trabajador/",
        "enviarDocumentoTrabajador": "/enviar_documento_trabajador/",
    },
    "revisionDocumentos": {
        "cancelarRevision": "/cancelar_revision_empresa/"
    },
    "remuneraciones": {
        "getTipoDocumentos": "/get_tipo_documentos/",
        "getPeriodos": "/get_periodos/",
        "actualizarDocumentos": "/actualizar_documentos_empresa/",
        "enviarEmpresaRevision": "/enviar_empresa_revision/",
        "generarDocumentosTrabajadores": "/generar_documentos_tabajadores/",
        "eliminarDocTrabajador": "/eliminar_documento_trabajador/",
        "eliminarFiniquito": "/eliminar_finiquito/",        
        "contratoFiniquito": {
            "subirDocumentoTrabajador": "/subir_documento_trabajador/",
            "getTipoDocumentos": "/get_tipos_documentos/",
            "getCausalesFiniquito": "/get_causales_fnq/",
            "generarTerminoCtroTmpl": "/generar_termino_contrato_tmpl/",
            "generarDocumentosCtroTmpl": "/generar_documentos_ctro_tmpl/",
            "getDocumentosTemplate": "/get_documentos_template/",
            "volverDocumentoAnterior": "/volver_documento_anterior",
        }
    },
    "portalTrabajador": {
        "getRegistros": "/get_registros",
        "getEmpresasTrabajador": "/get_empresas_trabajador",
        "registrarHorario": "/ingresar_registro"
    },
    "contabilidad": {
        "compraVenta": {
            "getPeriodosEmpresa": "/get_periodos_compra_venta_empresa",
            "getFiltrosEmpresa": "/get_filtros_empresa",
            "getFiltrosContabilizacion": "/get_filtros_contabilizacion",
            "getCuentasContabilizacion": "/get_filtros_cuentas",
            "getCV": "/get_compra_venta_empresa",
            "getContabilizacion": "/get_contabilizacion_empresa",
        },
        "balance": {
            "getPeriodoEmpresa": "/get_periodo_empresa_balance"
        }
    }
}