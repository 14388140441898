var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',[_c('CButton',{staticClass:"btnPrincipalVapp mb-1",staticStyle:{"width":"auto"},attrs:{"variant":"ghost","size":"sm","color":"success","disabled":_vm.isActualizandoEmpresas},on:{"click":_vm.actualizar}},[_c('CIcon',{class:_vm.classActualizar,attrs:{"content":_vm.$options.freeSet.cilLoopCircular}}),_vm._v(" "+_vm._s(_vm.textoBtnActualizarEmpresas)+" ")],1),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActualizandoEmpresas),expression:"isActualizandoEmpresas"},{name:"tippy",rawName:"v-tippy",value:({ 
                    placement: 'right',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google' 
                }),expression:"{ \n                    placement: 'right',\n                    arrow: true, \n                    arrowType: 'round', \n                    animation:'fade',\n                    theme: 'google' \n                }"}],staticClass:"btnPrincipalVapp mb-1",staticStyle:{"width":"auto"},attrs:{"size":"sm","color":"danger","content":"Cancelar Actualización"},on:{"click":_vm.cancelarActualizarEmpresas}},[_c('CIcon',{attrs:{"content":_vm.$options.freeSet.cilXCircle}}),_vm._v(" Detener Actualización ")],1),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.mensajesActualizacion.length>0),expression:"mensajesActualizacion.length>0"},{name:"tippy",rawName:"v-tippy",value:({ 
                    placement: 'right',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google' 
                }),expression:"{ \n                    placement: 'right',\n                    arrow: true, \n                    arrowType: 'round', \n                    animation:'fade',\n                    theme: 'google' \n                }"}],staticClass:"ml-1 mb-1",staticStyle:{"width":"auto"},attrs:{"size":"sm","content":"Mostrar/Ocultar"},on:{"click":function($event){_vm.mostrarMensajes = !_vm.mostrarMensajes}}},[_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarMensajes),expression:"mostrarMensajes"}],attrs:{"content":_vm.$options.freeSet.cilChevronTop}}),_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mostrarMensajes),expression:"!mostrarMensajes"}],attrs:{"content":_vm.$options.freeSet.cilChevronBottom}})],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_vm._v("Última Actualización: "),_c('span',{staticClass:"text-capitalize",staticStyle:{"color":"#0b7689"}},[_vm._v(_vm._s(_vm.formatearFecha(_vm.ultActualizacion)))])])],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarSugerencia),expression:"mostrarSugerencia"}]},[_c('CCol',{attrs:{"md":"4"}},[_c('span',{staticClass:"text-capitalize",staticStyle:{"color":"#7E1146"}},[_vm._v("**Ha pasado mucho tiempo sin actualizar, le sugerimos actualizar su información")])])],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.mostrarMensajes),expression:"mostrarMensajes"}]},[_c('CCol',{attrs:{"md":"8","lg":"8"}},[_c('a-table',{staticClass:"components-table-demo-nested mt-3",staticStyle:{"overflow":"auto"},attrs:{"row-key":"codEmpresa","columns":_vm.columnasMensajesAct,"data-source":_vm.mensajesActualizacion,"scroll":{ y: 300 },"size":"small"},scopedSlots:_vm._u([{key:"mensajes",fn:function(mensaje){return _vm._t("default",function(){return _vm._l((mensaje.mensajes),function(msj,index){return _c('CAlert',{key:index,attrs:{"show":"","color":"info"}},[_vm._v(" "+_vm._s(msj.mensaje)+" ")])})})}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }