<template>
    <div>
        <CRow>
            <CCol>
                <CButton
                    class="btnPrincipalVapp mb-1"
                    style="width:auto"
                    variant="ghost"
                    size="sm"
                    color="success"
                    @click="actualizar"
                    :disabled="isActualizandoEmpresas">
                    <CIcon :content="$options.freeSet.cilLoopCircular" :class="classActualizar" /> {{textoBtnActualizarEmpresas}}
                </CButton>
                
                <CButton
                    class="btnPrincipalVapp mb-1"
                    style="width:auto"
                    size="sm"
                    color="danger"
                    @click="cancelarActualizarEmpresas"
                    v-show="isActualizandoEmpresas"
                    content="Cancelar Actualización"
                    v-tippy="{ 
                        placement: 'right',
                        arrow: true, 
                        arrowType: 'round', 
                        animation:'fade',
                        theme: 'google' 
                    }">
                    <CIcon :content="$options.freeSet.cilXCircle" /> Detener Actualización
                </CButton>
                <CButton
                    class="ml-1 mb-1"
                    style="width:auto"
                    size="sm"
                    v-show="mensajesActualizacion.length>0"
                    @click="mostrarMensajes = !mostrarMensajes"
                    content="Mostrar/Ocultar"
                    v-tippy="{ 
                        placement: 'right',
                        arrow: true, 
                        arrowType: 'round', 
                        animation:'fade',
                        theme: 'google' 
                    }">
                    
                    <CIcon v-show="mostrarMensajes" :content="$options.freeSet.cilChevronTop" />
                    <CIcon v-show="!mostrarMensajes" :content="$options.freeSet.cilChevronBottom" />
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol md="4">Última Actualización: <span class="text-capitalize" style="color: #0b7689">{{ formatearFecha(ultActualizacion) }}</span></CCol>
        </CRow>
        <CRow v-show="mostrarSugerencia">
            <CCol md="4"><span class="text-capitalize" style="color: #7E1146">**Ha pasado mucho tiempo sin actualizar, le sugerimos actualizar su información</span></CCol>
        </CRow>
        
        <CRow v-show="mostrarMensajes">
            <CCol md="8" lg="8">
                <a-table
                    row-key="codEmpresa"
                    :columns="columnasMensajesAct" 
                    :data-source="mensajesActualizacion"
                    :scroll="{ y: 300 }"
                    class="components-table-demo-nested mt-3" 
                    size="small" 
                    style="overflow:auto;">
                    <slot slot="mensajes" slot-scope="mensaje">
                        <CAlert show color="info" :key="index" v-for="(msj, index) in mensaje.mensajes">
                            {{msj.mensaje}}
                        </CAlert>
                    </slot>
                </a-table>
            </CCol>
        </CRow>
    </div>
    
  
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'

    import { freeSet } from "@coreui/icons";
    import * as util from '@/views/utils/utilsFunctions'

    import { format, formatDistance, formatRelative, subDays } from "date-fns";
    import es from "date-fns/locale/es";

export default {
    name: "ActualizarEmpresas",
    freeSet,
    props: {
        tipoBd: String,
    },
    components: {
    },
    data() {
        return {

            urlBase: cons.port+"://" + cons.ipServer,

            tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
            tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
            idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,

            ultActualizacion: null,
            mostrarSugerencia: false,

            rqstActualizarEmpresas: null,
            isActualizandoEmpresas: false,
            classActualizar: "",
            textoBtnActualizarEmpresas: "Actualizar Empresas",
            mensajesActualizacion: [],
            mostrarMensajes: false,

            columnasMensajesAct: [
                { title: 'Cod Empresa', dataIndex: 'codEmpresa', width: '15%', ellipsis: true, align: 'center'},
                { title: 'Mensajes', scopedSlots: { customRender: 'mensajes' }, width: '70%', align: 'left'},
            ],
            

        };
    },
    created: function () {
    },
    beforeMount() {
        this.getUltimaActualizacion()
    },
    methods: {//return format(new Date(fechaDoc), cons.formatoFechaDocumentos, {locale: es})
        verDiferenciaFecha: function(){

            if(this.ultActualizacion == null)
                return false
            
            let diferencia = formatDistance(new Date(this.ultActualizacion), new Date(), {addSuffix: false, includeSeconds: true, locale: es}).match(/(\d+)\s+([a-zA-Záéíóúü]+)/)//.match(/\d+/)
            if (diferencia !== null) {
                if((diferencia[2] == "día" || diferencia[2] == "días") && parseInt(diferencia[1]) > 10)
                    return true
            }
            return false
        },
        formatearFecha: function(fecha){
            return util.formatFechaNormal(fecha)
        },
        getUltimaActualizacion: function(){
            
            var url = this.urlBase + apiUrls.general.getActualizacion//"/get_ultima_actualizacion/";
            
            axios({
                method: "POST", 
                "url": url,
                "data": {
                    "info": {
                        "tipoBd": this.tipoBd
                    }
                },
                "headers": {
                    'Authorization': this.tokenLogin
                }
            }
            ).then(result => {
                this.ultActualizacion = result.data.ultActualizacion

                this.mostrarSugerencia = this.verDiferenciaFecha(this.ultActualizacion)
            }, error => {
                //let mensaje = "Ups, Tuvimos un problema al obtener lafecha de última actualización"
                
                this.$notification.error(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
            })
            .finally(() => {
                this.classActualizar = ""
                this.isActualizandoEmpresas = false
                this.textoBtnActualizarEmpresas
                this.textoBtnActualizarEmpresas = "Actualizar Empresas"
            });
        },
        actualizar: function(){
            this.isActualizandoEmpresas = true
            this.textoBtnActualizarEmpresas = "Sincronizando Información Empresas"
            this.classActualizar = "imgr"
            this.mensajesActualizacion = []
            this.mostrarMensajes = false
            
            
            let mensaje = ""

            const axiosSource = axios.CancelToken.source();
            this.rqstActualizarEmpresas = { cancel: axiosSource.cancel, msg: "Cargando..." };
            var url = this.urlBase + apiUrls.general.actualizarBd
            
            axios({
                method: "POST", 
                "url": url,
                cancelToken: axiosSource.token,
                "data": {
                    "info": {
                        "actualizarTodoCliente": true,
                        "idCliente": this.idCliente,
                        "empresasActualizar": [],
                        "tipoBd": this.tipoBd
                    }
                },
                "headers": {
                    'Authorization': this.tokenLogin
                }
            }
            ).then(result => {
                mensaje = result.data
                this.mensajesActualizacion = mensaje.mensajesEmpresa
                this.getUltimaActualizacion()
                this.$notification.success(mensaje.mensajeGeneral, 
                    {  
                        timer: 10, position:"bottomRight", 
                        
                    }
                );
                
            }, error => {
                // mensaje = "Ups, Tuvimos un problema al actualizar, si el problema persiste contacte con nuestro soporte, Gracias."
                
                this.$notification.error(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
            })
            .finally(() => {
                this.classActualizar = ""
                this.isActualizandoEmpresas = false
                this.textoBtnActualizarEmpresas
                this.textoBtnActualizarEmpresas = "Actualizar Empresas"
            });

        },
        cancelarActualizarEmpresas: function(){
            if(this.rqstActualizarEmpresas != null){
                this.rqstActualizarEmpresas.cancel()
                this.rqstActualizarEmpresas.msg = "cancelado";
                this.rqstActualizarEmpresas = null;
                this.mostrarNotificaciones(4, "Se ha detenido la actualizacion");
            }
        },


    },
};
</script>